import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { AsyncMessageBundleManager, createAsyncMessageBundleManager, LocalizationContextBuilder } from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import { translations } from 'src/i18n/translations/translations-json';


import '@amzn/katal-components/styles.css';
import { KatSpinner } from '@amzn/katal-react';
import { installWidgetResizeWatcher } from '@amzn/paragon-core-data-context/lib/installWidgetResizeWatcher';
import { ParagonContextConsumerEl } from '@amzn/paragon-ui-react';

import AppInitWrapper from 'src/components/AppInitWrapper';
import { DemoWidget } from 'src/widgets/DemoWidget';
import { IssueSummaryWidget } from 'src/widgets/IssueSummaryWidget';
import { LandingPage } from 'src/widgets/LandingPage';
import { TransferWidget } from 'src/widgets/TransferCase';

import { DEFAULT_LOCALE } from 'src/constants';
import arbManifest from 'src/i18n/translations/arbManifest';

import './global.scss';
import { ServiceAPIContextProvider } from "src/service/service.context";
import ReplyWidget from './widgets/ReplyWidget/ReplyWidget';
import { ReplyCaseWidget } from './widgets/IssueSummaryWidget/components/widgets/ReplyCase/ReplyCaseWidget';
import { ActionPlanWidget } from './widgets/ActionPlanWidget';
import { ProviderMocks } from './components/ProviderMocks';
import { DemoGatingWidget } from './widgets/DemoGatingWidget';
// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

installWidgetResizeWatcher();

const CreateMbm = (locale: string): AsyncMessageBundleManager => {
  const defaultLocale = DEFAULT_LOCALE

  const localizationContextProvider = () =>
    new LocalizationContextBuilder().withLocale(locale).withDefaultLocale(defaultLocale).build()

  return createAsyncMessageBundleManager({
    arbManifest,
    localizationContextProvider,
    resolveResourcePack: async () => await new Promise((resolve, reject) => {
      try {
        resolve(translations)
      } catch (e) {
        reject(e)
      }
    })
  })
}


const mbm = CreateMbm(DEFAULT_LOCALE)

ReactDOM.render(
  <React.StrictMode>
    <MbmProvider mbm={mbm}>
      <AppInitWrapper>
        <React.Suspense fallback={<KatSpinner size="large" />}>
          <ProviderMocks>
            <ParagonContextConsumerEl>
              <ServiceAPIContextProvider>
                <HashRouter>
                  <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/demo-widget" element={<DemoWidget />} />
                    <Route path="/demo-gating-widget" element={<DemoGatingWidget />} />
                    <Route path="/transfer-case" element={<TransferWidget />} />
                    <Route path="/issue-summary" element={<IssueSummaryWidget />} />
                    <Route path="/llm-reply-widget" element={<ReplyWidget />} />
                    <Route path="/reply-case" element={<ReplyCaseWidget />} />
                    <Route path="/action-plan" element={<ActionPlanWidget />} />
                  </Routes>
                </HashRouter>
              </ServiceAPIContextProvider>
            </ParagonContextConsumerEl>
          </ProviderMocks>
        </React.Suspense>
      </AppInitWrapper>
    </MbmProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
