import { paragonWidgetDataContext } from "@amzn/paragon-context/lib/ParagonWidgetDataContext";
import { SchemaCaseElementsPrefix } from "src/widgets/IssueSummaryWidget/model/schemaElementsData";
import { TestConstants } from "./testContants";

export function installParagonWidgetDataContextProvider(el: HTMLElement) {
  const provider = paragonWidgetDataContext.createProvider(el);

  provider.value = {
    widgetId: "RequeueSnoozeWidgetId",
    widgetInstanceId: "RequeueSnoozeWidgetInstanceId",
    widgetVersion: "1",
    widgetState: {},
    metaData: {
      title: "Case Details",
    },
  };

  provider.functions.proxyGet = async (dataOperation, params, options) => {
    switch (dataOperation) {
      default:
        throw `Unhandled dataOperation ${dataOperation}`;
    }
  };

  provider.functions.proxyPost = async (actionOperation, params, options) => {
    switch (actionOperation) {
    case "getSchemaElementsData":
        return {
            data: {
                schemaElementsData: {[SchemaCaseElementsPrefix.LLM_SCHEMA_PREFIX]: {
                    schemaData: JSON.stringify({
                        PredictedText: "test text",
                        Metadata: {
                            Identifiers: {
                                ASIN: [TestConstants.asin],
                                OrderId: [TestConstants.orderId],
                                ShipmentId: [TestConstants.shipmentId]
                            },

                        }
                    })
            }}} as any,
            success: true,
            status: 200,
        };
      case "predictSOPsFromCaseInfo":
        return {
          data: {
            sopList: [{id: 'test-sop-1', title: "Test SOP 1", description: "This is a test SOP"}]
          },
          success: true,
          status: 200
        }
      default:
        throw `Unhandled actionOperation ${actionOperation}`;
    }
  };
}
