import React, { FunctionComponent, useEffect, useState } from 'react';

import { paragonCoreDataContext } from '@amzn/paragon-context/lib/ParagonCoreDataContext';
import '@amzn/paragon-dynamic-view-renderer/lib/styles.css';
import {
    ControlSpacing,
    PageSection,
    PageSectionPadding,
    useParagonContextConsumerEl,
    useParagonContext,
} from '@amzn/paragon-ui-react';
import '@amzn/paragon-ui-react/lib/styles.css';
import { paragonWeblabContext } from '@amzn/paragon-context/lib/ParagonWeblabContext';

export const DemoGatingWidget: FunctionComponent = () => {
    const el = useParagonContextConsumerEl();

    const {
        value: { userDetails, caseDetails },
    } = useParagonContext(el, paragonCoreDataContext);

    const { methods: weblabMethods } = useParagonContext(el, paragonWeblabContext)

    const [actionPlanTreatment, setActionPlanTreatment] = useState("C");
    const [shouldTriggerWeblab, setShouldTriggerWeblab] = useState(false);

    useEffect(() => {
        if (shouldTriggerWeblab) {
            weblabMethods.getWeblabWithTrigger("TEST_1157056").then((treatment) => {
                setActionPlanTreatment(treatment);
            });
        }
    }, [shouldTriggerWeblab])

    const shouldDisplay = () => {
        const allowedLogins = ["tsuff", "testUser1"]; // in prod we would load this from config file, service call, or hopefully a future ParagonPermissionsContext
        const allowedQueues = ["paragon-integration-tests@amazon.com", "tam-pro-gold-plat@amazon.com"]
        if (!caseDetails?.queue) {
            return false;
        }
        const agentQueueCheck = allowedLogins.includes(userDetails.agentLogin) && allowedQueues.includes(caseDetails.queue);
        if (agentQueueCheck && !shouldTriggerWeblab) {
            setShouldTriggerWeblab(true); // only want to trigger weblab as last step so we don't get false T1s
        }

        return agentQueueCheck && actionPlanTreatment == "T1";
    }

    if (!shouldDisplay()) {
        return <></>
    }

    //only displays if gating criteria is met
    return (
        <PageSection title="Demo Gating Widget" widgetId="demo-gating-widget">
            <PageSectionPadding>
                <ControlSpacing column>
                    {caseDetails && (
                        <div>
                            <h1>Case #{caseDetails.id}</h1>
                        </div>
                    )}
                    {userDetails && <h4> User Login: {userDetails.agentLogin}</h4>}
                    {caseDetails && <h4> queue: {caseDetails.queue}</h4>}
                    <h4>Weblab treatment: {actionPlanTreatment}</h4>
                </ControlSpacing>
            </PageSectionPadding>
        </PageSection>
    );
};
