import { WidgetFunctions } from '@amzn/paragon-context/lib/ParagonWidgetDataContext/models/WidgetFunctions';
import {
    GetNextSOPStepResponseData,
    PredictSOPsFromCaseInfoResponseData
} from '../model/ActionPlanTypes';
export interface CaseDocument {
    agentLogin: string
    caseId?: string
    caseSubject?: string
    caseContactText?: string
}

export async function getPredictedSOPs(
    numSops: number,
    widgetMethods: WidgetFunctions,
    caseDocument: CaseDocument
) {
    const params = {
        numSops: numSops,
        caseDocument: caseDocument
    }

    return await widgetMethods.proxyPost<PredictSOPsFromCaseInfoResponseData>(
        "predictSOPsFromCaseInfo",
        params
    );
}

export async function getActivityStep(
    sopId: string,
    widgetMethods: WidgetFunctions,
    caseDocument: CaseDocument,
    actions?: string,
    selectedOutcome?: string,
) {
    const params = {
        sopId: sopId,
        actions: actions,
        selectedOutcome: selectedOutcome,
        caseDocument: caseDocument
    }

    return await widgetMethods.proxyPost<GetNextSOPStepResponseData>(
        "getNextSOPStep",
        params
    );

}